import { usePathname } from 'next/navigation';

import { LANDING_PAGES_PATH } from '@/constants';
import { Brand } from '@/types/brand';

export interface UseLogoConfigArgs {
  brandCookie: Brand;
}

export default function useLogoConfig({ brandCookie }: UseLogoConfigArgs) {
  const pathname = usePathname();

  switch (brandCookie) {
    case Brand.PICTA:
      return { href: 'https://picta.com', brand: Brand.PICTA } as const;
    case Brand.WALGREENS:
      return {
        href: 'https://picta.com/walgreens',
        brand: Brand.WALGREENS,
      } as const;
    case Brand.SHUTTERFLY:
      return {
        href: '/',
        brand: Brand.SHUTTERFLY,
      } as const;
    default:
      return {
        href: LANDING_PAGES_PATH.includes(pathname) ? pathname : '/',
        brand: Brand.DEFAULT,
      } as const;
  }
}
