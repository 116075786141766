import { SVGProps } from 'react';
import clsx from 'clsx';

import styles from './styles.module.scss';

export default function ShutterfluyLogo({
  className,
  ...props
}: SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox="0 0 99 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={clsx(styles.color, className)}
      {...props}
    >
      <g clipPath="url(#clip0_14_130)">
        <mask
          id="mask0_14_130"
          style={{ maskType: 'luminance' }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="99"
          height="24"
        >
          <path d="M98.4 0.75H0.599976V23.25H98.4V0.75Z" fill="white" />
        </mask>
        <g mask="url(#mask0_14_130)">
          <path
            d="M78.9 5.75006V7.75006H76V17.6501H74V7.75006H72.6V5.75006H74C74 3.65006 74.8 1.95006 76.1 1.35006C77.4 0.650055 78.9 0.750055 78.9 0.750055V2.75006C78.7 2.75006 78.1 2.65006 77.3 3.05006C76.5 3.45006 76 4.35006 76 5.65006V5.75006H78.9Z"
            fill="currentColor"
          />
        </g>
        <mask
          id="mask1_14_130"
          style={{ maskType: 'luminance' }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="99"
          height="24"
        >
          <path d="M98.4 0.75H0.599976V23.25H98.4V0.75Z" fill="white" />
        </mask>
        <g mask="url(#mask1_14_130)">
          <path
            d="M42.9 7.74995V5.74995H40V0.949951H38V5.74995H36.6V7.74995H38V12.85C38 14.95 38.9 16.55 40.1 17.25C41.4 17.95 42.9 17.75 42.9 17.75V15.75C42.7 15.75 42.1 15.85 41.3 15.45C40.5 15.05 40 14.05 40 12.85V7.74995H42.9Z"
            fill="currentColor"
          />
        </g>
        <mask
          id="mask2_14_130"
          style={{ maskType: 'luminance' }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="99"
          height="24"
        >
          <path d="M98.4 0.75H0.599976V23.25H98.4V0.75Z" fill="white" />
        </mask>
        <g mask="url(#mask2_14_130)">
          <path
            d="M50.8 7.74995V5.74995H47.9V0.949951H45.9V5.74995H44.5V7.74995H45.9V12.85C45.9 14.95 46.8 16.55 48 17.25C49.3 17.95 50.8 17.75 50.8 17.75V15.75C50.6 15.75 50 15.85 49.2 15.45C48.4 15.05 47.9 14.05 47.9 12.85V7.74995H50.8Z"
            fill="currentColor"
          />
        </g>
        <mask
          id="mask3_14_130"
          style={{ maskType: 'luminance' }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="99"
          height="24"
        >
          <path d="M98.4 0.75H0.599976V23.25H98.4V0.75Z" fill="white" />
        </mask>
        <g mask="url(#mask3_14_130)">
          <path
            d="M32.6 12.65C32.6 14.55 31.7 15.75 30.1 15.75C28.6 15.75 27.6 14.55 27.6 12.65V5.75H25.6V12.75C25.6 15.85 27.3 17.75 30.1 17.75C32.9 17.75 34.6 15.85 34.6 12.75V5.75H32.6V12.65Z"
            fill="currentColor"
          />
        </g>
        <path
          d="M83.5 0.949951H81.5V17.5499H83.5V0.949951Z"
          fill="currentColor"
        />
        <mask
          id="mask4_14_130"
          style={{ maskType: 'luminance' }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="99"
          height="24"
        >
          <path d="M98.4 0.75H0.599976V23.25H98.4V0.75Z" fill="white" />
        </mask>
        <g mask="url(#mask4_14_130)">
          <path
            d="M6.49998 17.75C3.59998 17.75 1.09998 16.15 0.599976 14.75L2.09998 13.45C2.79998 14.45 4.19998 15.75 6.49998 15.75C8.79998 15.75 10.2 14.85 10.2 13.05C10.2 11.15 8.49998 10.55 6.09998 9.95C3.59998 9.35 1.09998 8.55 1.09998 5.25C0.999976 2.75 3.39998 0.75 6.49998 0.75C9.19998 0.75 11.2 2.05 11.8 3.05L10.2 4.35C9.39998 3.35 7.99998 2.75 6.49998 2.75C4.39998 2.75 2.99998 3.75 2.99998 5.35C2.99998 7.25 4.49998 7.65 6.79998 8.25C9.49998 8.95 12.1 9.95 12.1 13.15C12.2 15.95 9.89998 17.75 6.49998 17.75Z"
            fill="currentColor"
          />
        </g>
        <mask
          id="mask5_14_130"
          style={{ maskType: 'luminance' }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="99"
          height="24"
        >
          <path d="M98.4 0.75H0.599976V23.25H98.4V0.75Z" fill="white" />
        </mask>
        <g mask="url(#mask5_14_130)">
          <path
            d="M70.1 5.6499C68.6 5.6499 67.6 6.1499 66.9 7.0499V5.7499H64.9V17.6499H66.9V10.6499C66.9 8.7499 68.5 7.6499 70.1 7.6499C70.3 7.6499 70.5 7.6499 70.7 7.7499V5.7499C70.5 5.6499 70.3 5.6499 70.1 5.6499Z"
            fill="currentColor"
          />
        </g>
        <mask
          id="mask6_14_130"
          style={{ maskType: 'luminance' }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="99"
          height="24"
        >
          <path d="M98.4 0.75H0.599976V23.25H98.4V0.75Z" fill="white" />
        </mask>
        <g mask="url(#mask6_14_130)">
          <path
            d="M57.6 17.75C54.3 17.75 52 15.15 52 11.65C52 8.15005 54.2 5.55005 57.4 5.55005C60.6 5.65005 62.6 8.05005 62.6 11.75C62.6 11.95 62.6 12.05 62.6 12.35V12.45H54C54.3 14.55 55.6 15.95 57.7 15.95C59.1 15.95 60 15.55 61 14.45L61.1 14.35L62.3 15.45L62.2 15.55C60.8 17.15 59.6 17.75 57.6 17.75ZM60.6 10.85C60.4 9.05005 59.6 7.35005 57.3 7.35005C55.5 7.35005 54.2 8.75005 53.9 10.85H60.6Z"
            fill="currentColor"
          />
        </g>
        <mask
          id="mask7_14_130"
          style={{ maskType: 'luminance' }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="99"
          height="24"
        >
          <path d="M98.4 0.75H0.599976V23.25H98.4V0.75Z" fill="white" />
        </mask>
        <g mask="url(#mask7_14_130)">
          <path
            d="M19.1 5.64995C17.7 5.64995 16.8 6.24995 16.3 6.74995V0.949951H14.3V17.65H16.3V10.65C16.3 8.74995 17.5 7.54995 19.1 7.54995C20.7 7.54995 21.5 8.94995 21.5 10.85V17.5499H23.5V10.85C23.5 7.14995 21.5 5.64995 19.1 5.64995Z"
            fill="currentColor"
          />
        </g>
        <mask
          id="mask8_14_130"
          style={{ maskType: 'luminance' }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="99"
          height="24"
        >
          <path d="M98.4 0.75H0.599976V23.25H98.4V0.75Z" fill="white" />
        </mask>
        <g mask="url(#mask8_14_130)">
          <path
            d="M93.1 5.75V12.75C93.1 14.65 91.9 15.85 90.4 15.85C88.9 15.85 88.1 14.45 88.1 12.55V5.75H86.2V12.45C86.2 16.25 88.1 17.75 90.5 17.75C91.8 17.75 92.8 17.15 93.2 16.65V18.35C93.2 19.65 92.7 20.45 91.9 20.85C91.1 21.25 90.5999 21.15 90.2999 21.15V23.15C90.2999 23.15 91.8 23.35 93.1 22.65C94.3 21.95 95.2 20.35 95.2 18.25V5.75H93.1Z"
            fill="currentColor"
          />
        </g>
        <mask
          id="mask9_14_130"
          style={{ maskType: 'luminance' }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="99"
          height="24"
        >
          <path d="M98.4 0.75H0.599976V23.25H98.4V0.75Z" fill="white" />
        </mask>
        <g mask="url(#mask9_14_130)">
          <path
            d="M97.7999 16.15C97.7999 16.05 97.8 15.95 97.7 15.95C97.6 15.85 97.4999 15.75 97.2999 15.75H96.7999V16.85H97V16.45H97.2L97.5 16.85H97.7L97.4 16.45C97.7 16.45 97.7999 16.35 97.7999 16.15ZM97.2999 16.35H97V16.05H97.2999C97.3999 16.05 97.5 16.15 97.5 16.25C97.5 16.25 97.4999 16.35 97.2999 16.35ZM97.2999 15.25C96.6999 15.25 96.2 15.75 96.2 16.35C96.2 16.95 96.6999 17.45 97.2999 17.45C97.8999 17.45 98.4 16.95 98.4 16.35C98.4 15.75 97.8999 15.25 97.2999 15.25ZM97.2999 17.25C96.7999 17.25 96.4 16.85 96.4 16.35C96.4 15.85 96.7999 15.45 97.2999 15.45C97.7999 15.45 98.2 15.85 98.2 16.35C98.2 16.85 97.7999 17.25 97.2999 17.25Z"
            fill="currentColor"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_14_130">
          <rect
            width="97.8"
            height="22.5"
            fill="white"
            transform="translate(0.599976 0.75)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
