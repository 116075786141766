import styles from './styles.module.scss';

function NewBluePictaLogo() {
  return (
    <svg viewBox="0 0 36 17" fill="none" className={styles.a}>
      <path
        fill="#fff"
        d="M11.769 5.842a.905.905 0 0 1-.735-.38l-.007-.008a.074.074 0 0 0-.01-.011l-.02-.019a6.093 6.093 0 0 0-.498-.398c-.112-.084-.217-.162-.302-.232-.57-.466-.875-1.068-.859-1.696.015-.537.274-1.068.66-1.352.333-.244.686-.368 1.049-.368.281 0 .522.072.725.178.202-.106.443-.178.725-.178.362 0 .715.124 1.048.368.387.284.646.814.66 1.352.017.627-.288 1.23-.859 1.696-.085.07-.19.149-.302.232-.166.125-.393.295-.498.398l-.02.019-.01.012-.01.013a.905.905 0 0 1-.734.374h-.003Z"
      />
      <path
        fill="#fff"
        d="M12.497 2.267c.15 0 .32.052.51.192.24.175.628.945-.235 1.65-.207.17-.638.465-.863.686-.046.043-.09.091-.13.147l-.007.01-.008-.01a1.003 1.003 0 0 0-.13-.146c-.224-.222-.655-.517-.862-.687-.863-.705-.476-1.475-.236-1.65.19-.14.36-.192.51-.192.416 0 .67.405.726.503.055-.098.31-.503.725-.503Zm0-1.778c-.247 0-.49.037-.725.11a2.453 2.453 0 0 0-.725-.11c-.553 0-1.101.188-1.586.544-.61.446-1.001 1.229-1.023 2.041-.023.907.397 1.76 1.185 2.404.1.082.213.166.332.256.11.082.307.23.39.303a1.81 1.81 0 0 0 1.42.694h.007a1.81 1.81 0 0 0 1.428-.694c.081-.073.28-.221.39-.303.119-.09.23-.174.331-.255.788-.644 1.209-1.498 1.185-2.404-.022-.813-.414-1.595-1.023-2.042a2.679 2.679 0 0 0-1.586-.544Z"
      />
      <path
        fill="#fff"
        d="M2.127 15.389a.895.895 0 0 1-.9-.89V5.095c0-.49.403-.889.9-.889h2.01c.189 0 .364.058.51.156a3.91 3.91 0 0 1 1.769-.398c1.24 0 2.369.496 3.176 1.394.775.864 1.202 2.047 1.202 3.334 0 1.21-.426 2.345-1.201 3.196a4.243 4.243 0 0 1-3.177 1.392c-.448 0-.865-.06-1.25-.178V14.5c0 .49-.403.889-.9.889H2.126ZM5.952 7.71c-.491 0-.876.403-.876.916 0 .514.382.904.89.904.506 0 .889-.397.889-.904a.899.899 0 0 0-.903-.916Z"
      />
      <path
        fill="#fff"
        d="M6.416 4.852c2.035 0 3.478 1.64 3.478 3.839 0 2.008-1.43 3.699-3.478 3.699-.837 0-1.571-.255-2.15-.878V14.5H2.126V5.094h2.01v.776h.038c.567-.7 1.352-1.017 2.241-1.017m-.45 5.567a1.78 1.78 0 0 0 1.79-1.792c0-.979-.773-1.805-1.804-1.805-1.03 0-1.777.788-1.777 1.805s.773 1.792 1.79 1.792Zm.45-7.345c-.627 0-1.22.106-1.766.315a1.83 1.83 0 0 0-.514-.073H2.127A1.79 1.79 0 0 0 .326 5.094V14.5a1.79 1.79 0 0 0 1.801 1.778h2.138A1.79 1.79 0 0 0 6.067 14.5v-.343c.115.007.231.01.35.01 1.49 0 2.856-.598 3.846-1.685.924-1.014 1.432-2.36 1.432-3.79 0-1.504-.507-2.898-1.428-3.924-.981-1.092-2.349-1.693-3.85-1.693Z"
      />
      <path
        fill="#fff"
        d="M12.829 4.404H10.69c-.497 0-.9.398-.9.889v6.855c0 .491.403.89.9.89h2.139c.497 0 .9-.399.9-.89V5.293a.895.895 0 0 0-.9-.89Z"
      />
      <path
        fill="#fff"
        d="M12.828 5.293v6.855H10.69V5.293h2.138Zm0-1.778H10.69a1.79 1.79 0 0 0-1.801 1.778v6.855a1.79 1.79 0 0 0 1.801 1.778h2.138a1.79 1.79 0 0 0 1.802-1.778V5.293a1.79 1.79 0 0 0-1.802-1.778Z"
      />
      <path
        fill="#fff"
        d="M17.371 13.266a4.668 4.668 0 0 1-3.326-1.371 4.628 4.628 0 0 1-1.36-3.293c0-1.246.483-2.422 1.363-3.293a4.633 4.633 0 0 1 3.285-1.346c2.319 0 4.204 1.471 4.692 3.66a.881.881 0 0 1-.54 1.015.9.9 0 0 1 .38.277.88.88 0 0 1 .17.756c-.518 2.15-2.391 3.595-4.663 3.595Zm.013-5.555c-.514 0-.748.469-.748.904 0 .548.314.903.8.903.361 0 .57-.13.746-.462a.897.897 0 0 1 .467-.416.897.897 0 0 1-.486-.454c-.158-.337-.384-.475-.779-.475Z"
      />
      <path
        fill="#fff"
        d="M17.333 4.852c1.88 0 3.413 1.17 3.812 2.962h-2.164c-.309-.661-.863-.991-1.597-.991-.979 0-1.649.8-1.649 1.792 0 .991.709 1.792 1.7 1.792.722 0 1.224-.33 1.546-.94h2.177c-.425 1.766-1.958 2.91-3.787 2.91a3.767 3.767 0 0 1-3.786-3.775 3.746 3.746 0 0 1 3.748-3.75m0-1.777a5.574 5.574 0 0 0-3.924 1.607 5.478 5.478 0 0 0-1.626 3.92c0 1.489.575 2.872 1.62 3.918a5.569 5.569 0 0 0 3.968 1.635c1.305 0 2.522-.402 3.52-1.164a5.478 5.478 0 0 0 2.007-3.064 1.753 1.753 0 0 0-.151-1.3c.19-.364.248-.787.157-1.195a5.568 5.568 0 0 0-.732-1.78 5.42 5.42 0 0 0-1.267-1.39c-1.005-.777-2.24-1.187-3.573-1.187Z"
      />
      <path
        fill="#fff"
        d="M22.57 13.037a.895.895 0 0 1-.9-.889V7.585h-.155a.895.895 0 0 1-.901-.89v-1.6c0-.492.403-.89.9-.89h.156V2.743c0-.491.403-.89.9-.89h2.139c.497 0 .9.399.9.89v1.462h.13c.498 0 .901.398.901.89v1.6a.895.895 0 0 1-.9.89h-.13v4.563c0 .491-.404.89-.901.89H22.57Z"
      />
      <path
        fill="#fff"
        d="M24.709 2.743v2.351h1.03v1.602h-1.03v5.452H22.57V6.696h-1.056V5.094h1.056V2.743h2.138Zm0-1.778H22.57a1.79 1.79 0 0 0-1.802 1.778v.732c-.623.28-1.056.9-1.056 1.62v1.6c0 .72.433 1.34 1.056 1.62v3.834a1.79 1.79 0 0 0 1.802 1.777h2.138a1.79 1.79 0 0 0 1.801-1.777V8.303a1.777 1.777 0 0 0 1.03-1.607V5.094c0-.71-.42-1.322-1.03-1.607v-.744A1.79 1.79 0 0 0 24.71.965Z"
      />
      <path
        fill="#fff"
        d="M29.554 13.279c-1.267 0-2.42-.488-3.245-1.374-.798-.855-1.237-2.023-1.237-3.29 0-2.608 1.969-4.651 4.482-4.651.609 0 1.178.129 1.666.365a.906.906 0 0 1 .459-.124h2.138c.497 0 .9.398.9.89v7.053c0 .491-.403.89-.9.89h-2.138a.907.907 0 0 1-.449-.119 3.93 3.93 0 0 1-1.676.36Zm.309-5.567c-.477 0-.851.407-.851.928 0 .491.393.89.876.89.507 0 .89-.393.89-.915a.895.895 0 0 0-.915-.903Z"
      />
      <path
        fill="#fff"
        d="M29.554 4.852c.875 0 1.687.344 2.099.992h.026v-.75h2.138v7.054h-2.138v-.762h-.026c-.31.622-1.16 1.004-2.1 1.004-2.112 0-3.58-1.64-3.58-3.775 0-2.136 1.533-3.762 3.58-3.762m.335 5.567c1.005 0 1.79-.788 1.79-1.805s-.81-1.792-1.815-1.792-1.752.8-1.752 1.818a1.75 1.75 0 0 0 1.777 1.78Zm-.334-7.345c-1.49 0-2.868.592-3.88 1.668-.969 1.032-1.502 2.407-1.502 3.872 0 3.166 2.313 5.553 5.382 5.553.594 0 1.174-.1 1.7-.291.136.032.278.05.425.05h2.138a1.79 1.79 0 0 0 1.801-1.778V5.094a1.79 1.79 0 0 0-1.801-1.777h-2.138c-.152 0-.3.018-.44.053a4.835 4.835 0 0 0-1.686-.295Z"
      />
      <path
        fill="#FF66A1"
        d="M12.771 4.109c-.207.17-.637.464-.862.686-.046.043-.09.091-.13.146l-.008.012-.007-.012a1.002 1.002 0 0 0-.13-.145c-.224-.222-.655-.518-.863-.687-.863-.705-.475-1.475-.236-1.65.717-.526 1.161.178 1.236.311.076-.133.52-.837 1.236-.311.24.175.627.945-.236 1.65Z"
      />
      <path
        fill="#6177FB"
        d="M2.127 14.5V5.094h2.01v.776h.038c.567-.7 1.352-1.017 2.241-1.017 2.035 0 3.478 1.64 3.478 3.838 0 2.008-1.43 3.699-3.478 3.699-.837 0-1.571-.255-2.15-.877v2.986H2.126Zm5.629-5.872c0-.992-.773-1.805-1.803-1.805-1.03 0-1.778.788-1.778 1.805s.773 1.792 1.79 1.792a1.78 1.78 0 0 0 1.79-1.792ZM10.69 12.148V5.293h2.139v6.855H10.69ZM18.981 7.814c-.309-.661-.863-.991-1.597-.991-.979 0-1.649.8-1.649 1.792 0 .991.709 1.792 1.7 1.792.722 0 1.224-.33 1.546-.94h2.177c-.425 1.766-1.958 2.91-3.787 2.91a3.768 3.768 0 0 1-3.786-3.775 3.746 3.746 0 0 1 3.748-3.75c1.88 0 3.413 1.17 3.812 2.962h-2.164ZM22.57 12.148V6.696h-1.055V5.094h1.056V2.743h2.138v2.351h1.03v1.602h-1.03v5.452h-2.138ZM31.679 12.148v-.762h-.026c-.309.623-1.16 1.004-2.1 1.004-2.112 0-3.58-1.64-3.58-3.775s1.533-3.762 3.58-3.762c.876 0 1.688.343 2.1.991h.026v-.75h2.138v7.055h-2.138Zm0-3.533c0-.992-.812-1.792-1.816-1.792-1.005 0-1.752.8-1.752 1.817a1.75 1.75 0 0 0 1.777 1.78c.992 0 1.79-.788 1.79-1.805Z"
      />
    </svg>
  );
}

export default NewBluePictaLogo;
