'use client';

import clsx from 'clsx';
import Link from 'next/link';

import SendLogOnClickWrapper from '@/components/SendLogOnClickWrapper';
import { Brand } from '@/types/brand';

import NewBluePictaLogo from '../SVG/Logo/NewBluePictaLogo';
import PartnerLogo from '../SVG/Logo/PartnerLogo';
import PhotoPartnerLogo from '../SVG/Logo/PhotoPartnerLogo';
import RedPictaLogo from '../SVG/Logo/RedPictaLogo';
import ShutterfluyLogo from '../SVG/Logo/ShutterflyLogo';
import WalgreensOnlyLogo from '../SVG/Logo/WalgreensOnlyLogo';

import useLogoConfig from './useLogoConfig';

import styles from './styles.module.scss';

export interface LogoProps {
  brandCookie: Brand;
}

interface BrandLogoProps {
  brand: Brand;
}

function BrandLogo({ brand }: BrandLogoProps) {
  switch (brand) {
    case Brand.PICTA:
      return <NewBluePictaLogo />;
    case Brand.WALGREENS:
      return (
        <>
          <WalgreensOnlyLogo />
          <div className={styles.spacer} />
          <PhotoPartnerLogo />
        </>
      );
    case Brand.SHUTTERFLY:
      return <ShutterfluyLogo />;
    default:
      return (
        <>
          <RedPictaLogo />
          <div className={styles.spacer} />
          <PartnerLogo />
        </>
      );
  }
}

export default function Logo({ brandCookie }: LogoProps) {
  const { href, brand } = useLogoConfig({ brandCookie });

  return (
    <SendLogOnClickWrapper event="ui_header_logo_clicked">
      <Link
        href={href}
        className={clsx(
          styles.logo,
          styles[process.env.NEXT_PUBLIC_PARTNER || ''],
          brand === Brand.WALGREENS && styles.walgreensBrand,
          brand === Brand.SHUTTERFLY && styles.shutterflyBrand,
        )}
        aria-label={`${process.env.NEXT_PUBLIC_PARTNER} homepage`}
      >
        <BrandLogo brand={brand} />
      </Link>
    </SendLogOnClickWrapper>
  );
}
